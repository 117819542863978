<template>
  <div class="outside">
    <div class="top-banner">
      <img src="../../assets/img/digitize/declare-banner.png" alt="" srcset="">
    </div>
    <div class="introduction">
      <div class="edition_heart">
        <div class="title">系统介绍</div>
        <div class="titleEng">System Introduction</div>
        <el-row :gutter="60">
          <el-col :sm="24" :md="12">
            <div class="content" v-for="(item,index) in systemData" :key="index">
              {{ item.value }}
            </div>
          </el-col>
          <el-col :sm="24" :md="12">
            <div class="photos storeroom-img-contain">
              <img src="../../assets/img/digitize/declare.png" alt=""/>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: 'Declare',
  data(){
    return{
      systemData: [{
        value: "各地文旅局项目申报管理系统，一个旨在规范和优化文物保护项目审批流程的信息化平台。主要功能有项目申报记录、各地的博物馆管理、文物管理、文件数据总览等，提高了审批效率，确保了审批过程的透明性和可追溯性，并为各级文物管理部门提供了决策支持。"
      }, {
        value: "文旅局藏品可视化查看，方便项目申报时，实时查看进度"
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.photos img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.storeroom-img-contain {
  padding-bottom: 120px;
}

.photos-text {
  font-size: 20px;
  color: #071637;
  margin-top: 20px;
  text-align: center;
}

.outside {
  background-color: #F9F7F3;
}

.top-banner {
  width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  img{
    width: 100%;
    min-width: 1920px;
    height: 400px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 921px) {
  .edition_heart {
    width: 62.5%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 70px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 0px 0 0px;
    }

    .photos {
      padding-bottom: 70px;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 920px) {
  .edition_heart {
    width: 80%;
  }

  .outside {
    padding-top: 120px;

    .introduction {
      .title {
        padding-top: 80px;
      }
    }
  }

  .repair_case {
    .title {
      padding-top: 125px;
      font-size: 32px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 95%;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 87px 0 95px;
    }

    .photos {
      padding-bottom: 95px;
    }
  }
}

@media screen and (max-width: 768px) {
  .top-banner {
    display: none;
  }

  .edition_heart {
    width: 90%;
  }

  .outside {
    padding-top: 60px;
  }

  .introduction {
    .title {
      padding-top: 20px;
    }
  }

  .edition_heart {
    padding-top: 0px;
  }

  .repair_case {
    .title {
      padding-top: 30px;
      font-size: 24px;
    }

    .swiper-button-next {
      top: 30px;
    }

    .swiper-button-prev {
      top: 30px;
      left: 90%;
    }

    .photos {
      padding-bottom: 10px;
    }

    .swiper_slide {
      // height: 270px;
      display: flex;
      align-items: center;
    }

    .repair_case_photos {
      padding: 0;
    }
  }

  ::v-deep .edition_heart {
    padding-top: 30px;
  }
}

* {
  margin: 0;
  padding: 0;
}

.outside {
  .introduction {
    width: 100%;

    .title {
      padding-top: 80px;
      font-size: 32px;
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
      color: #333;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .content {
      margin-top: 24px;
      font-size: 16px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: left;
      color: #666;
      line-height: 30px;
      text-indent: 2em;
    }

    .photos {
      margin: 30px 0 0;
    }
  }

  .repair_case {
    width: 100%;
    height: auto;
    color: #333;
    position: relative;

    .title {
      font-family: PingFang SC, PingFang SC-Heavy;
      font-weight: 800;
      text-align: left;
    }

    .titleEng {
      border-bottom: 1px solid #D8D8D8;
      padding-bottom: 12px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(102, 102, 102, .4);
      line-height: 30px;
      padding-bottom: 18px;
      border-bottom: 1px solid #D8D8D8;
      position: relative;
    }

    .titleEng::before {
      content: "";
      display: block;
      width: 109px;
      height: 6px;
      background: #A40000;
      position: absolute;
      left: 0;
      bottom: 0;
    }

    .photos {
      --swiper-navigation-color: #fff;
      /* 单独设置按钮颜色 */
      --swiper-navigation-size: 16px;
      /* 设置按钮大小 */
      position: relative;

      .swiper-button-next {
        position: absolute;
      }

      .swiper-button-prev {
        position: absolute;
      }

      .swiper {
        width: 100%;
        height: auto;
        padding: 45px 0px 10px;

        .swiper_slide {
          // height: 270px;
          display: flex;
          align-items: center;
        }
      }

      .repair_case_photos {
        width: 100%;
        cursor: pointer;

        img {
          width: 95%;
          height: auto;
          border: 5px solid rgba(255, 255, 255, 0.4);
        }
      }
    }
  }

  // 版心
  .edition_heart {
    margin: 0px auto;
    position: relative;
  }
}
</style>
